<template>
  <wp-items-list
    content-type="news-articles"
    content-type-singular="news-article"
    content-name="News articles"
    content-name-singular="News article"
  />
</template>

<script>
import WpItemsList from '@/views/wp-item/wp-items-list/WpItemsList.vue'

export default {
  components: {
    WpItemsList,
  },
}
</script>

<style scoped>

</style>
